import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import BlogCards from "./BlogCards";
import axios from "axios";
import { ArrowRight } from "lucide-react";

const Blogs = () => {
  const [recentBlog, setRecentBlog] = useState(null);
  const [blogIds, setBlogIds] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);

  const BATCH_SIZE = 3; // Increased batch size to match the grid layout

  // Fetch all blog IDs initially
  useEffect(() => {
    const fetchBlogIds = async () => {
      try {
        const response = await axios.get(
          "https://blogs.rightone.ae/get-all-blogs-ids"
        );
        if (response.data.success) {
          const ids = response.data.data.map((blog) => blog.id);
          setBlogIds(ids);
          if (ids.length > 0) {
            fetchRecentBlog(ids[0]);
            setBlogIds(ids.slice(1));
          }
        } else {
          setError("Failed to fetch blog IDs.");
        }
      } catch (err) {
        setError("Error while fetching blog IDs.");
      } finally {
        setLoading(false);
      }
    };
    fetchBlogIds();
  }, []);

  // Fetch the most recent blog for the featured section
  const fetchRecentBlog = async (id) => {
    try {
      const response = await axios.get(
        `https://blogs.rightone.ae/get-blog-by-id/${id}`
      );
      if (response.data.success) {
        setRecentBlog(response.data.data[0]);
      }
    } catch (err) {
      setError("Error while fetching the recent blog.");
    }
  };

  // Fetch more blogs when scrolling
  const fetchMoreBlogs = useCallback(async () => {
    if (fetching || blogIds.length === 0) return;
    setFetching(true);
    const nextBatch = blogIds.slice(0, BATCH_SIZE);
    const remainingIds = blogIds.slice(BATCH_SIZE);
    try {
      const response = await axios.post(
        "https://blogs.rightone.ae/get-all-blogs-by-ids",
        { ids: nextBatch }
      );
      if (response.data.success) {
        setBlogs((prevBlogs) => [...prevBlogs, ...response.data.data]);
        setBlogIds(remainingIds);
      }
    } catch (err) {
      setError("Error while fetching more blogs.");
    } finally {
      setFetching(false);
    }
  }, [fetching, blogIds]);

  // Implement infinite scrolling
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 500
      ) {
        fetchMoreBlogs();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [fetchMoreBlogs]);

  // Initial fetch of blogs
  useEffect(() => {
    if (blogIds.length > 0 && blogs.length === 0 && !fetching) {
      fetchMoreBlogs();
    }
  }, [blogIds, blogs.length, fetching, fetchMoreBlogs]);

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-12 h-12 border-4 border-[#3D498A] border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  if (error) return <p className="text-red-500 text-center">{error}</p>;

  return (
    <>
      <h1 className="text-4xl font-bold text-center text-[#3D498A] my-2 mt-10">
        Our Blogs
      </h1>

      <div className="container mx-auto px-4 py-4">
        {/* Featured Blog Section */}
        {recentBlog && (
          <div className="my-4 flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-12 overflow-hidden">
            <div className="w-full md:w-1/2" data-aos="fade-up-right">
              <img
                src={`https://blogs.rightone.ae/public/uploads/${recentBlog.photo}`}
                alt={recentBlog.title}
                className="w-full h-[400px] max-h-[400px] 2xl:max-h-[400px] object-cover rounded-[18px] shadow-lg"
              />
            </div>
            <div className="w-full md:w-1/2" data-aos="fade-up-left">
              <p
                className="text-[#3D498A] mt-10"
                style={{ fontSize: "clamp(14px,1vw, 20px)" }}
              >
                {recentBlog.date}{" "}
                {recentBlog.category && `• ${recentBlog.category}`}
              </p>
              <h2
                className="text-[#3D498A] mt-2 font-extrabold"
                style={{ fontSize: "clamp(28px,1.6vw, 28px)" }}
              >
                {recentBlog.title}
              </h2>
              <p className="text-[#666] text-justify text-[14px] mt-2">
                {recentBlog.short_description}
              </p>
              <Link
                to={`/blog-details?id=${
                  recentBlog.id
                }&&title=${encodeURIComponent(recentBlog.title)}`}
                className="px-6 py-3 mt-6 inline-block rounded-full bg-white text-[#3D498A] font-semibold text-lg shadow-lg hover:scale-105 transition-transform flex items-center gap-2 w-fit"
              >
                Read More
                <ArrowRight className="w-5 h-5" />
              </Link>
            </div>
          </div>
        )}

        {/* Blog Cards Grid */}
        <h2 className="text-3xl font-bold text-[#3D498A] mb-8 mt-16">
          Latest Articles
        </h2>
        <BlogCards blogs={blogs} />

        {/* Loading Spinner for More Blogs */}
        {fetching && (
          <div className="flex justify-center items-center py-10">
            <div className="w-10 h-10 border-4 border-[#3D498A] border-t-transparent rounded-full animate-spin"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default Blogs;
