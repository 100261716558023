import './index.css'
import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home/Home'
import Contact from './Pages/Contact/Contact';
import About from './Pages/About/About';
import CostCalculator from './Pages/Home/CostCalculator';
import Testimonials from './Pages/Home/Testimonials';
import GoldenVisa from './Pages/GoldenVisa/GoldenVisaHome';
import Mainland from './Pages/CompanyFormation/Mainland/Mainland'
import Freezone from './Pages/CompanyFormation/Freezone/Freezone'
import Offshore from './Pages/CompanyFormation/Offshore/Offshore'
import Corporatebankaccountopening from './Pages/Corporate Services/Corporate-Bank-Account-Opening/corporatebankaccountopening';
import AOS from 'aos';
import 'aos/dist/aos.css';
import AccountandBookeeping from './Pages/Corporate Services/Accout and Bookeeping Service/AccountandBookeeping';
import ProServices from './Pages/Corporate Services/Pro Services/ProServices';
import OtherServices from './Pages/Corporate Services/Other Services/OtherServices';
import CompanyFormation from './Pages/Corporate Services/Liscensing Service/CompanyFormation'
import Blogs from './Pages/Blogs/Blogs'
import BlogDetails from './Pages/BlogDetails/BlogDetails';
import CompanyProfile from './Pages/CompanyProfile/CompanyProfile';
// In your App.jsx, add this functionality
import { useLocation } from 'react-router-dom';

function App() {
  const location = useLocation();
  
  useEffect(() => {
    // Track page view when location changes
    if (window.gtag) {
      window.gtag('config', 'G-FLG2VCP5Q5', {
        page_path: location.pathname,
      });
    }
  }, [location]);
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />

        <Route exact path="/companyProfile" element={<CompanyProfile />} />
        <Route exact path="/testimonials" element={<Testimonials />} />
        <Route exact path="/about" element={<About />} />

        {/* Company Formation */}
        <Route exact path="/mainland" element={<Mainland />} />
        <Route exact path="/freezone" element={<Freezone />} />
        <Route exact path="/offshore" element={<Offshore />} />

        <Route exact path="/goldenVisa" element={<GoldenVisa />} />

        {/* Corporate Services */}
        <Route exact path="/company-formation" element={<CompanyFormation/>} />
        <Route exact path="/pro-services" element={<ProServices />} />
        <Route exact path="/accounting-bookkeeping" element={<AccountandBookeeping />} />
        <Route exact path="/corporate-bank-account-opening" element={<Corporatebankaccountopening />} />
        <Route exact path="/other-services" element={<OtherServices />} />
        
        <Route exact path="/blogs" element={<Blogs />} />
        <Route exact path="/blog-details" element={<BlogDetails />} />

        <Route exact path="/costcalculator" element={<CostCalculator />} />
              
        <Route exact path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
}
export default App;