// BlogCards.jsx
import React from "react";
import { Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";
// import BlogDetails from "./pages/BlogDetails";

const BlogCards = ({ blogs, handlePreview }) => {
  return (
    <div className="container mx-auto">
      <div className="grid md:grid-cols-3 gap-10 p-3 mt-8">
        {blogs.map((blog) => (
          <div
            key={blog.id}
            className="shadow-xl p-2 rounded-[20px] transition-all hover:scale-95 flex flex-col h-full"
            data-aos="fade-up"
          >
            {/* Image Section */}
            <div>
              <img
                className="max-h-[250px] w-full object-cover rounded-t-lg"
                src={`https://blogs.rightone.ae/public/uploads/${blog.photo}`}
                alt={blog.title}
              />
            </div>

            {/* Content Section */}
            <div className="mt-4">
              <hr />
              <p className="text-[#3D498A] m-3">{blog.date}</p>
              <h2
                className="m-3 text-[#3D498A] font-montserrat text-[16px] font-extrabold leading-[26px]"
                style={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  minHeight: "52px",
                }}
              >
                {blog.title}
              </h2>
              <p
                className="ml-3 mb-3 text-[#666]"
                style={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 5,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  minHeight: "7em",
                }}
              >
                {blog.short_description}
              </p>
            </div>

            {/* Button Section - Always at bottom */}
            <div className="mt-auto p-3">
              {/* The flex container might not be needed if only one button remains, but leaving it is fine */}
              <div className="flex flex-row justify-start">
                {" "}
                {/* Changed justify-between to justify-start */}
                {/* Read More Button */}
                <Link
                  to={`/blog-details?id=${blog.id}&title=${encodeURIComponent(
                    blog.title
                  )}`}
                  className="px-6 py-3 rounded-full bg-white text-[#3D498A] font-semibold text-lg shadow-lg hover:scale-105 transition-transform flex items-center gap-2"
                >
                  Read More
                  <ArrowRight className="w-5 h-5" />
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogCards;
