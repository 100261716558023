import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { FaSquareWhatsapp } from "react-icons/fa6";
import { ArrowRight } from "lucide-react";

const BlogDetails = () => {
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const blogId = query.get("id");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchBlog = async () => {
      if (!blogId) {
        setError("No blog ID provided");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `https://blogs.rightone.ae/get-blog-by-id/${blogId}`
        );

        if (response.data.success) {
          setBlog(response.data.data[0]);
        } else {
          setError("Failed to fetch blog");
        }
      } catch (err) {
        setError("An error occurred while fetching the blog");
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [blogId]);

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-12 h-12 border-4 border-[#3D498A] border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  if (error) return <p className="text-red-500 text-center my-10">{error}</p>;

  return (
    <>
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-3xl mx-auto">
          {/* Removed the first title to avoid duplication */}
          <h1 className="text-3xl font-semibold text-[#3D498A] mb-4">{blog.title}</h1>
          <div className="flex items-center text-[#666] mb-6">
            <p>{blog.date}</p>
            {blog.category && (
              <>
                <span className="mx-2">•</span>
                <p>{blog.category}</p>
              </>
            )}
          </div>
          <img
            src={`https://blogs.rightone.ae/uploads/${blog.photo}`}
            alt={blog.title}
            className="w-full h-[400px] object-cover my-6 rounded-lg shadow-lg"
          />
          <div 
            className="mt-8 text-[#444] text-justify prose prose-lg max-w-none"
            dangerouslySetInnerHTML={{ __html: blog.description }}
          />
          
          {/* Call to action section */}
          <div className="mt-16 p-6 bg-gray-50 rounded-lg shadow-md">
            <h3 className="text-xl font-bold text-[#3D498A] mb-4">Need assistance with your business in UAE?</h3>
            <p className="mb-6">Contact our experts at Right One Corporate Services for professional guidance.</p>
            <div className="flex flex-col sm:flex-row gap-4">
              <a
                href="https://wa.me/+971526919516"
                target="_blank"
                rel="noopener noreferrer"
                className="px-6 py-3 rounded-full bg-white text-[#3D498A] font-semibold text-lg shadow-lg hover:scale-105 transition-transform flex items-center justify-center gap-2"
              >
                Contact on WhatsApp
                <FaSquareWhatsapp className="w-5 h-5" />
              </a>
              <a
                href="https://rightone.ae/contact"
                className="px-6 py-3 rounded-full bg-white text-[#3D498A] font-semibold text-lg shadow-lg hover:scale-105 transition-transform flex items-center justify-center gap-2"
              >
                Get in Touch
                <ArrowRight className="w-5 h-5" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
